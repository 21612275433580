<template>
  <v-container style="padding-top:4rem">
    <div>
      <v-row>
        <v-col cols="4">
          <v-text-field
            dense
            outlined
            placeholder="Certificaat "
            v-model="form.naam"
          ></v-text-field>

          <v-btn color="primary" @click="addCertificate()">
            Certificaat toevoegen
          </v-btn>
        </v-col>

        <v-col cols="8">
          <v-card>
            <v-card-title>
              Certificering toevoegen
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Certificaat opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="table-container">
              <v-data-table
                :headers="headers"
                :items="certificaten"
                :search="search"
                height="200px"
                fixed-header
                disable-pagination
                :hide-default-footer="true"
                class="elevation-1"
              >
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiCertifcaat from "../api/apiCertificaat";
export default {
  data: () => ({
    form: {
      naam: "",
    },
    certificaten: [],
    search: "",
    headers: [
      {
        text: "Naam",
        value: "naam",
        sortable: true,
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    await this.getCertificates();
  },

  methods: {
    async getCertificates() {
      apiCertifcaat.haalCertificaat().then((data) => {
        this.certificaten = data;
      });
    },
    resetForm() {
      this.form.naam = "";
    },
    async addCertificate() {
      try {
        if (!this.form.naam) {
          this.$swal.fire("Foutmelding", "Vul het veld in", "error");
          return;
        }
        const response = await apiCertifcaat.createCertificaat(this.form);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.resetForm();
        this.getCertificates();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    async deleteStatus(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze certificaat wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiCertifcaat.deleteCertificaat(id);
        this.getCertificates();
        this.$swal.fire(
          "Verwijderd",
          "De certificaat is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>

<style></style>
