<template>
  <v-container style="padding-top:4rem">
    <div>
      <v-row>
        <v-col cols="4">
          <v-text-field
            dense
            outlined
            placeholder="Naam categorie"
            v-model="form.naam"
          ></v-text-field>

          <v-btn color="primary" @click="addCategorie()">
            Blog categorie toevoegen
          </v-btn>
        </v-col>

        <v-col cols="8">
          <v-card>
            <v-card-title>
              Categorie
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Categorie opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="table-container">
              <!-- Treeview voor categorieën en subcategorieën -->
              <v-treeview
                :items="categorieen"
                item-text="naam"
                item-children="children"
                activatable
                active-class="primary--text"
                selectable
              ></v-treeview>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiBlogCategorie from "../api/apiBlogCategorie";
export default {
  data: () => ({
    form: {
      naam: "",
    },
    categorieen: [],

    search: "",
    headers: [
      {
        text: "Naam",
        value: "naam",
        sortable: true,
      },
      {
        text: "Acties",
        value: "Actions",
        sortable: true,
      },
    ],
  }),

  async mounted() {
    await this.fetchCategorieen();
  },

  methods: {
    fetchCategorieen() {
      apiBlogCategorie.haalCategorieOp().then((data) => {
        this.categorieen = data;
      });
    },

    async addCategorie() {
      try {
        if (!this.form.naam) {
          this.$swal.fire("Foutmelding", "De naam mag niet leeg zijn", "error");
          return;
        }
        const response = await apiBlogCategorie.createCategorie(this.form);

        this.form.naam = "";

        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");

        this.fetchCategorieen();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    async deleteCategorie(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze categorie wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiBlogCategorie.deleteCategorie(id);
        this.fetchCategorie();
        this.$swal.fire(
          "Verwijderd",
          "De categorie is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>
