<template>
  <v-container>
    <a href="../public/export.xlsx" download>
      <v-icon>mdi-download</v-icon>
      Download voorbeeld
    </a>

    <v-file-input
      ref="fileInput"
      show-size
      label="File input"
      v-model="file"
      accept=".xls,.xlsx"
    ></v-file-input>

    <v-btn
      @click="uploadFile"
      data-testid="upload-button"
      variant="flat"
      size="small"
      color="#0a7da0"
    >
      Upload file</v-btn
    >
  </v-container>
</template>

<script>
import apiUpload from "../api/apiUpload.js";
import Swal from "sweetalert2";
export default {
  data: () => ({
    file: null,
  }),
  methods: {
    async uploadFile() {
      try {
        await apiUpload.upload(this.file);
        this.file = null;
        Swal.fire({
          title: "Het uploaden",
          text: `Updating `,
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } catch (error) {
        Swal.fire({
          title: "There is an error.",
          text: `Updating `,
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },
  },
};
</script>
<style>
.swal2-container {
  z-index: 50000 !important;
}
</style>
