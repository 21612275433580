<template>
    <Upload />
  </template>
  
  <script>
  import Upload from "@/components/upload/Upload";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        Upload,
    },
    metaInfo: {
      title: "Upload ",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>
  