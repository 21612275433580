import { API } from "./api.js";
export default {
  async upload(file) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await API.post("/tas/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
