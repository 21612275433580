
<template>
    <Categorie />
  </template>
  
  <script>
  import Categorie from "@/components/instellingen/Categorie";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        Categorie,
    },
    metaInfo: {
   title: "Categorie bekijken",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>