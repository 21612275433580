<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title>
          Overzicht Blogs
          <v-spacer></v-spacer>
          <v-text-field
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Opzoeken"
            single-line
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>
          <div style="text-align: right">
            <div style="margin-bottom: 2rem; margin-top: 1rem">
              <v-btn color="primary" @click="addTraining()">
                Blog toevoegen
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="isFetching ? [] : blogs"
          :search="search"
          :loading="isFetching"
          disable-pagination
          :fixed-header="true"
          height="75vh"
          :hide-default-footer="true"
          class="elevation-1"
        >
          <template v-slot:top></template>
          <template v-slot:[`item.prijs`]="{ value }">€ {{ value }}</template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-icon small color="red" @click="deleteTraining(item.id)">
              mdi-delete
            </v-icon>
            <v-icon small color="green" @click="openSingleTraining(item.id)">
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import apiBlog from "../api/apiBlog";
export default {
  data: () => ({
    isFetching: false,
    search: "",
    items: [],
    blogs: [],
    headers: [
      {
        text: "Titel",
        value: "titel",
        sortable: true,
      },
      {
        text: "Onderwerp",
        value: "onderwerp",
        sortable: true,
      },
      {
        text: "Categorie",
        value: "categorie",
        sortable: true,
      },

      {
        text: "Actions",
        value: "Actions",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    this.isFetching = true;
    await this.getBlogs();
  },

  methods: {
    addTraining() {
      this.$router.push("/Blogtoevoegen");
    },
    async getBlogs() {
      this.isFetching = true;
      await apiBlog
        .haalBlogOp()
        .then((data) => {
          this.blogs = data;
        })
        .catch((error) => {
          console.error("Error fetching Cursus data:", error);
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    openSingleTraining(id) {
      this.$router.push(`/Bloginformatie/${id}`);
    },
    async deleteTraining(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze Blog wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        try {
          await apiBlog.deleteBlog(id);
          await this.getBlogs();
          this.$swal.fire(
            "Verwijderd",
            "De Blog is succesvol verwijderd",
            "success"
          );
        } catch (error) {
          console.error("Fout bij het verwijderen van de Blog:", error);
        }
      }
    },
  },
};
</script>
<style scoped>
.hide-actions {
  display: none;
}
@media print {
  .hide-print {
    display: none !important;
  }
}
</style>
